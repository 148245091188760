import ENV from "./environment";
import axios from "axios";
const HelperMap = {
  async renderMaker(detail) {
    let data = detail;
    let html = "";
    let location = {
      lat: data.lat,
      lon: data.long,
    };
    if (data.type === "pickup") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("assets/images/work/pick.png") +
        '" style="width: 30px;margin-top: 0px;"/></div>"';
    } else if (data.type === "send") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("assets/images/work/send.png") +
        '" style="width: 30px;margin-top: 0px;"/>"';
    } else if (data.type === "driver") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("assets/images/work/truckpin.png") +
        '" style="width: 30px;margin-top: 0px;"/>"';
    } else {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("assets/images/work/abnormal.png") +
        '" style="width: 30px;margin-top: 0px;"/>"';
    }
    let mark = await new window.longdo.Marker(location, {
      title:
        data.type === "pickup"
          ? "จุดรับ"
          : data.type === "send"
          ? "จุดส่ง"
          : "แจ้งปัญหา",
      draggable: false,
      clickable: false,
      weight: window.longdo.OverlayWeight.Top,
      icon: {
        html,
      },
    });
    return mark;
  },

  getBound(data, boundData) {
    // console.log(boundData);
    // return;
    let bound = boundData;
    if (bound.maxLon === null || bound.maxLon < parseFloat(data.long)) {
      bound.maxLon = parseFloat(data.long);
    }
    if (bound.maxLat === null || bound.maxLat < parseFloat(data.lat)) {
      bound.maxLat = parseFloat(data.lat);
    }
    if (bound.minLon === null || bound.minLon > parseFloat(data.long)) {
      bound.minLon = parseFloat(data.long);
    }
    if (bound.minLat === null || bound.minLat > parseFloat(data.lat)) {
      bound.minLat = parseFloat(data.lat);
    }
    return bound;
  },

  async getMarkerFromStatus(status) {
    console.log("status", status);
    let mark = [];
    for (const { ele, index } of status.map((ele, index) => ({
      ele,
      index,
    }))) {
      if (ele.workTrackingDetail) {
        console.log(ele);
        if (
          ele.workTrackingDetail.epod_lat &&
          ele.workTrackingDetail.epod_long
        ) {
          // this
          let marker = await this.renderMaker({
            lat: ele.workTrackingDetail.epod_lat,
            long: ele.workTrackingDetail.epod_long,
            type: ele.work_status === 8 ? "pickup" : "send",
          });
          // console.log("marker", marker);
          mark.push(marker);
        }
      }
      if (ele.workAbnormal) {
        if (ele.workAbnormal.abnormal_lat && ele.workAbnormal.abnormal_long) {
          // this
          let marker = await this.renderMaker({
            lat: ele.workAbnormal.abnormal_lat,
            long: ele.workAbnormal.abnormal_long,
            type: "abnormal",
          });
          // console.log("marker", marker);
          mark.push(marker);
        }
      }
    }

    return mark;
  },

  async getDistance(data) {
    // console.log(data);
    let returnData = {
      allInterval: "",
      allDistance: "",
      orderDistance: "",
      orderInterval: "",
    };
    let allDistance = returnData.allDistance;
    let allInterval = returnData.allInterval;
    for (let [index, e] of data.entries()) {
      if (index !== 0) {
        let positionData = {
          key: ENV.MAP_KEY[ENV.ENV].KEY_1,
          flon: data[index - 1].long,
          flat: data[index - 1].lat,
          tlon: e.long,
          tlat: e.lat,
          mode: "c",
          type: 1,
        };
        let link =
          "https://api.longdo.com/RouteService/geojson/route?&key=" +
          ENV.MAP_KEY[ENV.ENV].KEY_1 +
          "&flon=" +
          data[index - 1].long +
          "&flat=" +
          data[index - 1].lat +
          "&tlon=" +
          e.long +
          "&tlat=" +
          e.lat +
          "&mode=c&type=1";
        // console.log(
        //   "https://api.longdo.com/RouteService/geojson/route?&key=c78c11c6f1148e1102a79aa1975f3808&flon=102.0523853&flat=14.9036788&tlon=102.0599344&tlat=14.9022394&mode=c&type=1"
        // );
        // console.log();
        // return;
        await axios
          .get(link, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            // await calDistance(positionData);
            // allDistance = "";
            // allInterval = "";
            let order_interval;
            let order_distance;
            // const { data } = response;
            // console.log(response, 'fasdfasfas');
            if (allDistance === "" || allInterval === "") {
              allDistance = parseFloat(response.data.data.distance / 1000);
              //จากวินาทีเป็นนาที
              allInterval = parseFloat(response.data.data.interval / 60);
            } else {
              allDistance =
                parseFloat(allDistance) +
                parseFloat(response.data.data.distance / 1000);
              // เวลาตำแน่งใหม่บวกกัน จากวินาทีเป็นนาที
              allInterval =
                parseFloat(allInterval) +
                parseFloat(response.data.data.interval / 60);
            }
            order_distance = allDistance.toFixed(2);
            if (allInterval.toFixed(2) > 60) {
              // จากนาทีเป็น ชม
              let interval = parseFloat(allInterval / 60);
              // job.orderDetail.order_interval =
              //     interval.toFixed(2) + " ชม.";
              let string = interval.toFixed(2) + "";
              let time = string.split(".");
              if (time.length === 2) {
                if (time[1] > 60) {
                  let int = parseInt(time[1]);
                  let min = parseFloat(int / 60);
                  let intH = parseInt(time[0]);
                  let hours = parseFloat(intH + min);
                  let hToS = hours.toFixed(2).toString();
                  let showTime = hToS.split(".");
                  if (showTime[1] === 60) {
                    order_interval = parseInt(showTime[0]) + 1 + " ชม. ";
                  } else {
                    order_interval =
                      showTime[0] + " ชม. " + showTime[1] + " นาที";
                  }
                } else {
                  // console.log(
                  //     "testt",
                  //     time[0] + " ชม. " + time[1] + " นาที"
                  // );
                  if (time[1] === 60) {
                    order_interval = parseInt(time[0]) + 1 + " ชม. ";
                  } else {
                    order_interval = time[0] + " ชม. " + time[1] + " นาที";
                  }
                }
              }
              // else {
              //     console.log("test", time);
              //     job.orderDetail.order_interval =
              //         time[0] + " ชม. " + time[1] + " นาที";
              // }
            } else {
              if (allInterval % 1 !== 0) {
                order_interval =
                  Math.floor(allInterval) +
                  " นาที " +
                  (60 * (allInterval % 1)).toFixed(0) +
                  " วินาที";
              } else {
                order_interval = allInterval.toFixed(0) + " นาที";
              }
            }
            // console.log('asdfasdfasdf');
            // console.log(order_interval, order_distance, 'fasdfasdfasdf');
            returnData = {
              allInterval: allInterval,
              allDistance: allDistance,
              orderDistance: order_distance,
              orderInterval: order_interval,
            };
            // if (index === data.length - 1) {
            //   console.log(returnData, 'returnData');
            //   return returnData;
            // }
            // console.log(returnData);
            // return returnData;
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
      // console.log(index, e);
    }
    console.log(returnData);
    return returnData;
  },
  async getSuggest(search) {
    let detail = [];
    await axios
      .get(
        "https://search.longdo.com/mapsearch/json/suggest?keyword=" +
          search +
          "&local=th&version=2&callback=longdo.callback.suggest4&key=" +
          ENV.MAP_KEY[ENV.ENV].KEY_1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log("getPlace", response.data);
        let place = response.data
          .replace("longdo.callback.suggest4(", "")
          .slice(0, -1);
        console.log("place");

        detail = JSON.parse(place).data;
      })
      .catch(() => {
        // console.log('error')
      });
    return detail;
  },
  async getPlace(search) {
    let detail = [];
    await axios
      .get(
        "https://search.longdo.com/mapsearch/json/search?keyword=" +
          search +
          "&key=" +
          ENV.MAP_KEY[ENV.ENV].KEY_1 +
          "&version=2&callback=longdo.callback.search4",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log("getPlace", response);
        let place = response.data
          .replace("longdo.callback.search4(", "")
          .slice(0, -1);
        detail = JSON.parse(place).data[0];
      })
      .catch(() => {
        // console.log('error')
      });
    return detail;
  },
  async getPlaceFormLatLong(location) {
    let detail = [];
 
    await axios
      .get(
        "https://api.longdo.com/map/services/address?lat=" +
          location.lat.toString().replace(" ", "") +
          "&nowater=true&noaoi=true&noroad=true&noelevation=true&lon=" +
          location.lon.toString().replace(" ", "") +
          "&key=" +
          ENV.MAP_KEY[ENV.ENV].KEY_1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("getPlaceFormLatLong", response);
        detail = response.data;
      })
      .catch(() => {
        // console.log('error')
      });
    return detail;
  },

  async ApproximateTime(_distance, _speed) {
    console.log("ApproximateTime", _distance, _speed);
    const time = Number(_distance) / Number(_speed);
    let calculateTime = time;
    console.log("calculateTime", calculateTime);
    let day = 0;
    let hour = 0;
    let minute = 0;
    while (calculateTime > 0) {
      if (calculateTime >= 8) {
        day += 1;
        calculateTime -= 8;
      } else {
        hour = Math.floor(calculateTime);
        minute = Math.round((calculateTime - hour) * 60);
        calculateTime = 0; // End loop
      }
    }
    return `${day !== 0 ? `${day} วัน, ` : ""} ${
      hour !== 0 ? `${hour} ชั่วโมง, ` : ""
    } ${minute !== 0 ? `${minute} นาที` : ""}`;
  },
};
export default HelperMap;
