// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-switch {
  display: flex;
  gap: 10px;
}

.buttun-switch-option {
  flex: 1 1;
  padding: 10px;
  box-shadow: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
  display: flex;
  border-radius: 10px;

  /* border-style: solid;
  border-width: 0.5px; */
}
/* .buttun-switch-option:hover {
  background-color: #8ca3ba;
} */

.button-switch-otpion-active {
  border-style: solid;
  border-color: rgba(255, 0, 0, 0.5);
  box-shadow: rgba(255, 0, 0, 0.25) 0px 1px 4px;
}

/* .button-switch-otpion-first {
  border-top-left-radius: 5px;
}

.button-switch-otpion-last {
  border-top-right-radius: 5px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/button/buttonSwitch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,SAAO;EACP,aAAa;EACb,aAAa;EACb,2CAA2C;EAC3C,eAAe;EACf,aAAa;EACb,mBAAmB;;EAEnB;wBACsB;AACxB;AACA;;GAEG;;AAEH;EACE,mBAAmB;EACnB,kCAAkC;EAClC,6CAA6C;AAC/C;;AAEA;;;;;;GAMG","sourcesContent":[".button-switch {\n  display: flex;\n  gap: 10px;\n}\n\n.buttun-switch-option {\n  flex: 1;\n  padding: 10px;\n  box-shadow: 1;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n  cursor: pointer;\n  display: flex;\n  border-radius: 10px;\n\n  /* border-style: solid;\n  border-width: 0.5px; */\n}\n/* .buttun-switch-option:hover {\n  background-color: #8ca3ba;\n} */\n\n.button-switch-otpion-active {\n  border-style: solid;\n  border-color: rgba(255, 0, 0, 0.5);\n  box-shadow: rgba(255, 0, 0, 0.25) 0px 1px 4px;\n}\n\n/* .button-switch-otpion-first {\n  border-top-left-radius: 5px;\n}\n\n.button-switch-otpion-last {\n  border-top-right-radius: 5px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
