import React, { useEffect, useState } from "react";
import API from "../../api/work/work";
import { useParams } from "react-router-dom";
import helper from "utils/helper";
import HelperMap from "utils/helperMap";
import MapWithCall from "components/map/MapWithCall";
import "./workPublic.css";
import SwipeUp from "components/swipe/SwipeUp";
import { Divider } from "@mui/material";
import WorkStatus from "components/work/workStatus";
import WorkLocation from "components/work/workLocation";
import WorkTruck from "components/work/workTruck";
import WorkProduct from "components/work/workProduct";
import WorkDateTime from "components/work/workDateTime";
import WorkCarrier from "components/work/workCarrier";
import WorkRemark from "components/work/workRemark";
const api = API.create();
const WorkPublicDetail = () => {
  const { id_secret } = useParams();
  const [call, setCall] = useState();
  const [loadMap, setLoadmap] = useState(false);
  const [markerStatus, setMarkerStatus] = useState();
  const [workDetail, setWorkDetail] = useState();
  const [datetime, setDatetime] = useState({});
  const [driverLoc, setDriverLoc] = useState();
  const [socketId, setSocketId] = useState("test");
  const [distanceTime, setDistanceTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [service, setService] = useState({
    has_document_return: false,
    has_labor: false,
    labor_load_amount: 0,
    labor_unload_amount: 0,
  });
  const [location, setLocation] = useState([
    {
      type: 0,
      name: "",
      lat: "",
      long: "",
      address: "",
      district: "",
      subdistrict: "",
      province: "",
      postal_code: "",
      contact_name: "",
      contact_phone: "",
      place_name: "",
      place_type_id: 0,
      remark: "",
      document: "",
      document_file: [],
      product: [
        {
          name: "",
          product_id: "",
          product_name: "",
          product_size: "",
          product_width: "",
          product_length: "",
          product_height: "",
          product_volume: "",
          product_weight: "",
          product_count_type: "",
          product_amount: "",
          product_image: [null, null, null, null, null],
          product_image_per_route: [],
        },
      ],
    },
    {
      type: 1,
      name: "",
      lat: "",
      long: "",
      address: "",
      district: "",
      subdistrict: "",
      province: "",
      postal_code: "",
      contact_name: "",
      contact_phone: "",
      place_name: "",
      place_type_id: 0,
      remark: "",
      document: "",
      document_file: [],
      product: [
        {
          name: "",
          product_id: "",
          product_name: "",
          product_size: "",
          product_width: "",
          product_length: "",
          product_height: "",
          product_volume: "",
          product_weight: "",
          product_count_type: "",
          product_amount: "",
          product_image: [null, null, null, null, null],
          product_image_per_route: [],
        },
      ],
    },
  ]);

  useEffect(() => {
    if (id_secret) {
      getDetatilWork(id_secret);
    }
  }, [id_secret]);

  useEffect(() => {
    if (loadMap) {
      setMarker();
      if (
        workDetail?.workTrackings.filter(
          (e) => e.work_status === 5 || e.work_status === 6
        ).length > 0
      ) {
        getDriver();
        setInterval(() => {
          getDriver();
        }, 20000);
      }
    }
  }, [loadMap]);
  const getDetatilWork = async (id) => {
    await api
      .getWorkDetailPublic({ id_secret: id })
      .then((response) => {
        console.log("getWorkDetail", response);
        let data = response.data.results;
        console.log(response.data.results);

        // console.log(bread);
        response.data.results.truck_type.image =
          helper.pathImage() + response.data.results.truck_type.image;
        setWorkDetail(response.data.results);
        data.workTrackings = data.workTrackings.reverse();
        let loc = helper.workDetailProduct(response.data.results.workLocations);
        setService({
          has_document_return: data.has_document_return,
          has_labor: data.has_labor,
          labor_load_amount: data.labor_load_amount ?? 0,
          labor_unload_amount: data.labor_unload_amount ?? 0,
          has_driver_assist_load_unload: data.has_driver_assist_load_unload,
        });
        setLocation(loc);
        setDatetime({
          date: {
            startDate: data.load_date_start,
            endDate: data.load_date_end,
          },
          time: {
            time_start: data.load_time_start.slice(0, 5),
            time_end: data.load_time_end.slice(0, 5),
          },
        });
        setDistanceTime({
          // allInterval: data.total_duration,
          allDistance: data.total_distance,
          orderDistance: data.total_distance,
          // orderInterval: helper.secToText(data.total_duration),
        });
        setDuration(data.total_duration);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDriver = async () => {
    await api
      .getDriverLoc({
        work_id: workDetail?.id,
        client_id: socketId,
      })
      .then((response) => {
        console.log("getDriverLoc", response.data.results);
        setDriverLoc({
          lat: response.data.results.lat,
          long: response.data.results.long,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const setMarker = async () => {
    let list = [];
    for (const { ele } of location.map((ele) => ({
      ele,
    }))) {
      if (ele.lat !== "" && ele.long !== "") {
        // console.log(ele.lat, ele.long);
        const dot = await new window.longdo.Marker(
          { lat: ele.lat, lon: ele.long },
          {
            draggable: false,
            clickable: true,
            weight: window.longdo.OverlayWeight.Top,
            icon: {
              html:
                '<div class=""><img src="' +
                (ele.type === 0
                  ? require("assets/images/work/pickup_pin.png")
                  : require("assets/images/work/send_pin.png")) +
                '" style="height: 30px"/></div>',
            },
          }
        );
        list.push(dot);
      }
    }
    setCall(list);
    let mark_status = await HelperMap.getMarkerFromStatus(
      workDetail?.workTrackings
    );
    setMarkerStatus(mark_status);
    console.log("mark_status", mark_status);
  };

  return (
    <div className="container">
      <div className="headWorkCode">{workDetail?.work_code ?? ""}</div>
      <MapWithCall call={call} marker={markerStatus} driver={driverLoc} />
      <SwipeUp
        onSwipe={() => {
          if (!loadMap) {
            setLoadmap(true);
          }
        }}
        headerContent={
          <div className="headSwipeWork">
            <div
              style={{
                width: 50,
                height: 5,
                backgroundColor: "#8ca3ba",
                position: "absolute",
                top: 2.5,
                transform: "translate(-50%, -50%)",
                borderRadius: "0px 0px 10px 10px",
                left: "50%",
              }}
            ></div>
            <div>ระยะทางประมาณ {distanceTime?.orderDistance ?? 0} กม.</div>
            {/* <div>
              ระยะเวลาเดินทางประมาณ {distanceTime?.orderInterval ?? "- ชั่วโมง"}
            </div> */}
            <div>ระยะเวลาเดินทางประมาณ {duration?.replace(/,/g, " ")}</div>
          </div>
        }
      >
        {workDetail && (
          <div>
            <div style={{ textAlign: "center" }}>ติดตามสถานะ</div>
            <Divider orientation="horizontal" />
            <WorkStatus data={workDetail?.workTrackings ?? null} />
            <Divider orientation="horizontal" />
            <div style={{ textAlign: "center", paddingTop: "10px" }}>
              รายละเอียดงาน
            </div>
            <WorkLocation value={location} />
            <Divider orientation="horizontal" />
            <WorkTruck value={workDetail?.truck_type} />
            <Divider orientation="horizontal" />
            <WorkProduct
              value={location.length > 0 ? location[0].product : null}
              workType={workDetail?.work_code.includes("STL") ? "stl" : "ftl"}
            />
            <Divider orientation="horizontal" />
            {service?.has_document_return ||
            service?.has_labor ||
            service.has_driver_assist_load_unload ? (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  padding: "10px 0px 10px 0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px 10px 5px 10px",
                  }}
                >
                  <img
                    src={require("assets/images/work/service.png")}
                    height={40}
                    alt={"service"}
                  />
                </div>
                <div style={{ flex: 1, textAlignLast: "start" }}>
                  <div
                    style={{
                      fontWeight: 500,
                      opacity:
                        service.has_document_return ||
                        service.has_labor ||
                        service.has_driver_assist_load_unload
                          ? 1
                          : 0.5,
                    }}
                  >
                    {"บริการเสริม"}
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      opacity:
                        service.has_document_return ||
                        service.has_labor ||
                        service.has_driver_assist_load_unload
                          ? 1
                          : 0.3,
                    }}
                  >
                    {service.has_document_return ||
                    service.has_labor ||
                    service.has_driver_assist_load_unload ? (
                      <div>
                        {service.has_document_return ? (
                          <div>ต้องการส่งเอกสารนำกลับ</div>
                        ) : null}
                        {service.has_labor && service.labor_load_amount > 0 ? (
                          <div>
                            ต้องการคนงานยกสินค้าขึ้น {service.labor_load_amount}{" "}
                            คน
                          </div>
                        ) : null}
                        {service.has_labor &&
                        service.labor_unload_amount > 0 ? (
                          <div>
                            ต้องการคนงานยกสินค้าลง {service.labor_unload_amount}{" "}
                            คน
                          </div>
                        ) : null}
                        {service.has_driver_assist_load_unload ? (
                          <div>ต้องการคนขับช่วยยกสินค้า</div>
                        ) : null}
                      </div>
                    ) : (
                      "ต้องการส่งเอกสารนำกลับ ต้องการคนงานยกสินค้าขึ้นลง  ต้องการคนขับช่วยยกสินค้า"
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            <Divider orientation="horizontal" />
            <WorkDateTime value={datetime} />
            <Divider orientation="horizontal" />
            {workDetail?.carrier ? (
              <div>
                <WorkCarrier
                  carrier={workDetail?.carrier}
                  driver={workDetail?.driver}
                  truck={
                    workDetail?.workTrackings?.filter(
                      (e) => e.work_status === 4
                    ).length > 0
                      ? workDetail?.workTrackings?.filter(
                          (e) => e.work_status === 4
                        )[0].workTrackingDetail.truck
                      : null
                  }
                />
                <Divider orientation="horizontal" />
              </div>
            ) : null}
            {workDetail?.work_remark && workDetail?.work_remark !== "" ? (
              <div>
                <WorkRemark value={workDetail?.work_remark} />
                <Divider orientation="horizontal" />
              </div>
            ) : null}
          </div>
        )}
      </SwipeUp>
    </div>
  );
};

export default WorkPublicDetail;
