import React, { useState } from "react";

import "./selectLocation.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PlaceIcon from "@mui/icons-material/Place";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModalSelectMap from "./modalSelectMap";
import { RemoveCircleOutline } from "@mui/icons-material";

const SelectLocation = (props) => {
  const {
    value,
    onChange,
    is_detail = false,
    workType,
    closeFav = false,
    closeCurrent = false,
  } = props;
  const [data, setData] = useState(null);
  const [showLocDetail, setShowLocDetail] = useState(false);
  const [showProduct, setShowProduct] = useState(false);

  const callback = async () => {
    console.log("is_detail");
  };
  return (
    <div className="select-location">
      {value.map((ele, index) => {
        // console.log(ele);
        // console.log(
        //   ele.place_name !== ""
        //     ? ele.place_name
        //     : ele.address !== ""
        //     ? ele.address
        //     : ele.type === 0
        //     ? "จุดรับสินค้า?*"
        //     : "จุดส่งสินค้า?*"
        // );
        return (
          <div className="select-location-route" key={"route" + index}>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              onClick={() => {
                setData({ value: ele, index: index });
              }}
            >
              <PlaceIcon
                sx={{ color: ele.type === 0 ? "#121f43" : "#ff316f" }}
              />
              <MoreVertIcon sx={{ opacity: 0.3 }} />
            </div>
            <div
              style={{ flex: 1, paddingLeft: 10 }}
              onClick={() => {
                setData({ value: ele, index: index });
              }}
            >
              <div
                style={{
                  color:
                    // ele.subdistrict !== "" &&
                    ele.district !== "" && ele.province !== ""
                      ? "#121f43"
                      : "#ff316f",
                }}
              >
                {ele.place_name !== ""
                  ? ele.place_name
                  : ele.address !== ""
                  ? ele.address
                  : ele.type === 0
                  ? "จุดรับสินค้า?*"
                  : "จุดส่งสินค้า?*"}
              </div>
              <div
                style={{
                  fontSize: 12,
                  opacity:
                    // ele.subdistrict !== "" &&
                    ele.district !== "" && ele.province !== "" ? 1 : 0.3,
                }}
              >
                {ele.subdistrict !== "" &&
                ele.district !== "" &&
                ele.province !== ""
                  ? ele.subdistrict + " " + ele.district + " " + ele.province
                  : "(เลือกสถานที่ที่คุณต้องการมให้" +
                    (ele.type === 0 ? "เข้ารับ" : "จัดส่ง") +
                    "สินค้า)"}
              </div>
            </div>
            {index > 1 && !is_detail ? (
              <div
                style={{ alignSelf: "center", cursor: "pointer" }}
                onClick={() => {
                  let tmp = [...value];
                  tmp.splice(index, 1);
                  onChange(tmp);
                }}
              >
                <RemoveCircleOutline sx={{ color: "#121f43" }} />
              </div>
            ) : null}
            <div
              style={{ alignSelf: "center" }}
              onClick={() => {
                setData({ value: ele, index: index });
              }}
            >
              <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
            </div>
          </div>
        );
      })}
      {is_detail ? null : workType === "stl" ? null : (
        <div>
          <div
            className="select-location-route select-location-add"
            onClick={() => {
              value.push({
                type: 1,
                name: "",
                lat: "",
                long: "",
                address: "",
                district: "",
                subdistrict: "",
                province: "",
                postal_code: "",
                contact_name: "",
                contact_phone: "",
                place_name: "",
                place_type_id: 0,
                remark: "",
                document: "",
                document_file: [],
                product: [
                  {
                    product_id: "",
                    product_name: "",
                    product_size: "",
                    product_width: "",
                    product_length: "",
                    product_height: "",
                    product_volume: "",
                    product_weight: "",
                    product_count_type: "",
                    product_amount: "",
                    product_image: [null, null, null, null, null],
                    product_image_per_route: [],
                  },
                ],
              });
              onChange(value);
            }}
            style={{ paddingBottom: 10 }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <AddCircleIcon />
            </div>
            <div style={{ flex: 1, paddingLeft: 10, alignSelf: "center" }}>
              <div>เพิ่มจุดส่งสินค้า</div>
            </div>
          </div>
        </div>
      )}
      <ModalSelectMap
        close={() => {
          setData(null);
        }}
        value={data ? data.value : null}
        onChange={(val) => {
          setData({ ...data, value: val });
        }}
        confirm={() => {
          let tmp = [...value];
          tmp[data.index] = data.value;
          onChange(tmp);
          setData(null);
        }}
        show={data ? true : false}
        workType={workType}
        profile_id={props.profile_id}
        is_detail={is_detail}
        closeFav={closeFav}
        closeCurrent={closeCurrent}
      />
    </div>
  );
};

export default SelectLocation;
