import React, { useEffect, useState } from "react";
import "./CardBackhaul.css";
import { Box, Button, Grid } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ModalCustom from "components/modal/ModalCustom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";
import helper from "utils/helper";
const CardBackhaul = ({ data = null, day = "" }) => {
  console.log("CardBackhaul", data, day);
  const [openModal, setOpenModal] = useState(false);
  const [clipboardInfo, setClipboardInfo] = useState("");

  useEffect(() => {
    let text =
      "สนใจใช้บริการรถว่าง" +
      `\nผู้ขนส่ง id: ${data?.carrier_id}` +
      `\nต้นทาง: ${data?.load_district} ${data?.load_province}` +
      `\nปลายทาง: ${data?.unload_district} ${data?.unload_province}` +
      `\n${
        day === "monday"
          ? "วันจันทร์"
          : day === "tuesday"
          ? "วันอังคาร"
          : day === "wednesday"
          ? "วันพุธ"
          : day === "thursday"
          ? "วันพฤหัสบดี"
          : day === "friday"
          ? "วันศุกร์"
          : day === "saturday"
          ? "วันเสาร์"
          : day === "sunday"
          ? "วันอาทิตย์"
          : ""
      } ${
        day === "monday"
          ? data.time_monday
          : day === "tuesday"
          ? data.time_tuesday
          : day === "wednesday"
          ? data.time_wednesday
          : day === "thursday"
          ? data.time_thursday
          : day === "friday"
          ? data.time_friday
          : day === "saturday"
          ? data.time_saturday
          : day === "sunday"
          ? data.time_sunday
          : ""
      }` +
      `\nจำนวน ${
        day === "monday"
          ? data.amount_monday
          : day === "tuesday"
          ? data.amount_tuesday
          : day === "wednesday"
          ? data.amount_wednesday
          : day === "thursday"
          ? data.amount_thursday
          : day === "friday"
          ? data.amount_friday
          : day === "saturday"
          ? data.amount_saturday
          : day === "sunday"
          ? data.amount_sunday
          : ""
      } คัน` +
      `\nค่าขนส่ง ${
        day === "monday"
          ? helper.addCommaNoDigit(data.freight_monday)
          : day === "tuesday"
          ? helper.addCommaNoDigit(data.freight_tuesday)
          : day === "wednesday"
          ? helper.addCommaNoDigit(data.freight_wednesday)
          : day === "thursday"
          ? helper.addCommaNoDigit(data.freight_thursday)
          : day === "friday"
          ? helper.addCommaNoDigit(data.freight_friday)
          : day === "saturday"
          ? helper.addCommaNoDigit(data.freight_saturday)
          : day === "sunday"
          ? helper.addCommaNoDigit(data.freight_sunday)
          : ""
      } บาท`;

    setClipboardInfo(text);
  }, [data, day]);
  return (
    <div>
      <ModalCustom
        content={() => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                wordBreak: "break-word",
                fontSize: "20px",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              ขั้นตอนการใช้งาน
            </div>
            <div
              style={{
                wordBreak: "break-word",
                fontSize: "16px",
                fontWeight: 500,
                paddingTop: "20px",
              }}
            >
              1.โปรดกดปุ่ม “ คัดลอก “ ด้านล่างเพื่อคัดลอกข้อความรายละเอียดรถว่าง
            </div>
            <CopyToClipboard
              text={clipboardInfo}
              onCopy={() => {
                Swal.fire({
                  title: "คัดลอกสำเร็จ",
                  icon: "success",
                });
              }}
            >
              <div style={{ paddingTop: `20px` }}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "2px solid #EC1F26",
                    color: "#EC1F26",
                  }}
                >
                  คัดลอก
                </Button>
              </div>
            </CopyToClipboard>
            <div
              style={{
                wordBreak: "break-word",
                fontSize: "16px",
                fontWeight: 500,
                paddingTop: "20px",
              }}
            >
              2.โปรดกดปุ่ม “ ติดต่อเจ้าหน้าที่ “
              ด้านล่างเพื่อส่งข้อความให้เจ้าหน้าที่ในไลน์
            </div>
            <div style={{ paddingTop: `20px` }}>
              <Button
                variant="contained"
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  backgroundColor: "#EC1F26",
                  //   border: "2px solid #EC1F26",
                  color: "white",
                }}
                onClick={() => window.open("https://lin.ee/Jlsusua", "_blank")}
              >
                ติดต่อเจ้าหน้าที่
              </Button>
            </div>
          </div>
        )}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      />
      <Box
        sx={{
          borderLeft: `5px solid #EC1F26`,
          bgcolor: "white",
          padding: "10px 10px 10px 20px",
          borderRadius: "10px",
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        }}
      >
        <Grid container columnSpacing={0.5} rowSpacing={1}>
          <Grid item xs={5.5}>
            <div className="box-in-card-backhaul">
              <div
                style={{ fontSize: "10px", color: "#8CA3BA", fontWeight: 500 }}
              >
                ต้นทาง
              </div>
              <div
                style={{ fontSize: "14px", color: "#EC1F26", fontWeight: 600 }}
              >
                {data?.load_province}
              </div>
              <div
                style={{ fontSize: "10px", color: "black", fontWeight: 500 }}
              >
                {data?.load_district}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ArrowForwardIosRoundedIcon
              sx={{ fontSize: "36px", color: "#EC1F26" }}
            />
          </Grid>
          <Grid item xs={5.5}>
            <div className="box-in-card-backhaul">
              <div
                style={{ fontSize: "10px", color: "#8CA3BA", fontWeight: 500 }}
              >
                ปลายทาง
              </div>
              <div
                style={{ fontSize: "14px", color: "#EC1F26", fontWeight: 600 }}
              >
                {data?.unload_province}
              </div>
              <div
                style={{ fontSize: "10px", color: "black", fontWeight: 500 }}
              >
                {data?.unload_district}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="box-in-card-backhaul">
              <div
                style={{ fontSize: "10px", color: "#8CA3BA", fontWeight: 500 }}
              >
                WeMove id
              </div>
              <div style={{ fontSize: "14px", color: "black" }}>
                {data?.carrier_id}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="box-in-card-backhaul">
              <div
                style={{ fontSize: "10px", color: "#8CA3BA", fontWeight: 500 }}
              >
                เวลาเข้ารับสินค้า
              </div>
              <div style={{ fontSize: "10px", color: "black" }}>
                {day === "monday"
                  ? data.time_monday
                  : day === "tuesday"
                  ? data.time_tuesday
                  : day === "wednesday"
                  ? data.time_wednesday
                  : day === "thursday"
                  ? data.time_thursday
                  : day === "friday"
                  ? data.time_friday
                  : day === "saturday"
                  ? data.time_saturday
                  : day === "sunday"
                  ? data.time_sunday
                  : ""}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="box-in-card-backhaul">
              <div
                style={{ fontSize: "10px", color: "#8CA3BA", fontWeight: 500 }}
              >
                จำนวนรถว่าง
              </div>
              <div style={{ fontSize: "14px", color: "black" }}>
                {day === "monday"
                  ? data.amount_monday
                  : day === "tuesday"
                  ? data.amount_tuesday
                  : day === "wednesday"
                  ? data.amount_wednesday
                  : day === "thursday"
                  ? data.amount_thursday
                  : day === "friday"
                  ? data.amount_friday
                  : day === "saturday"
                  ? data.amount_saturday
                  : day === "sunday"
                  ? data.amount_sunday
                  : ""}{" "}
                คัน
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{ fontSize: "10px", color: "#8CA3BA", fontWeight: 500 }}
              >
                ค่าขนส่ง
              </div>
              <div
                style={{ fontSize: "22px", color: "#EC1F26", fontWeight: 600 }}
              >
                {day === "monday"
                  ? helper.addCommaNoDigit(data.freight_monday)
                  : day === "tuesday"
                  ? helper.addCommaNoDigit(data.freight_tuesday)
                  : day === "wednesday"
                  ? helper.addCommaNoDigit(data.freight_wednesday)
                  : day === "thursday"
                  ? helper.addCommaNoDigit(data.freight_thursday)
                  : day === "friday"
                  ? helper.addCommaNoDigit(data.freight_friday)
                  : day === "saturday"
                  ? helper.addCommaNoDigit(data.freight_saturday)
                  : day === "sunday"
                  ? helper.addCommaNoDigit(data.freight_sunday)
                  : ""}{" "}
                บาท
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Button
                variant="contained"
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  backgroundColor: "#EC1F26",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                onClick={() => setOpenModal(true)}
              >
                สนใจใช้งาน
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CardBackhaul;
