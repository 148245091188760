import React from "react";
import "./buttonSwitch.css";
const ButtonSwitch = (props) => {
  const { option = [], onClick } = props;
  console.log("option", option);
  return (
    <div className="button-switch">
      {option.map((ele, index) => {
        return (
          <div
            key={index}
            className={
              "buttun-switch-option " +
              // (index === 0
              //   ? "button-switch-otpion-first "
              //   : index + 1 === option.length
              //   ? "button-switch-otpion-last "
              //   : " ") +
              (ele.active ? "button-switch-otpion-active" : "")
            }
            onClick={() => {
              if (!ele.active) {
                onClick(index);
              }
            }}
          >
            <div>
              <img
                src={ele.icon}
                alt={ele.title}
                style={{ width: 30, objectFit: "contain" }}
              />
            </div>
            <div style={{ flex: 1, paddingLeft: 10 }}>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: ele.active ? "#FC0C0C" : "black",
                }}
              >
                {ele.title}
              </div>
              <div
                style={{
                  fontSize: 12,
                  color: ele.active ? "black" : `#8CA3BA`,
                }}
              >
                {ele.subtitle}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ButtonSwitch;
