import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import "./modalSelectMap.css";
import HelperMap from "utils/helperMap";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
import Swal from "sweetalert2";
import InputLongdoAutoComplete from "components/input/InputLongdoAutoComplete";
import ENV from "utils/environment";
// const google = window.google;
const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "100vh",
  // maxHeight: "90vmin",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  pt: 3,
  px: 3,
  pb: 3,
};
const ModalSelectMap = (props) => {
  const {
    value,
    close,
    show,
    onChange = () => {},
    confirm,
    profile_id,
    workType,
    is_detail = false,
    closeFav = false,
    closeCurrent = false,
  } = props;
  const [currentLocation, setCurrentLocation] = useState();
  const [currentPin, setCurrentPin] = useState();
  const [showLocDetail, setShowLocDetail] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [showFavPlace, setShowFavPlace] = useState(false);
  const [favPlaceList, setFavPlaceList] = useState([]);

  const [filter, setFilter] = useState({
    page: 1,
    per_page: 5,
    search_by: "location_name",
    search_val: "",
    active: true,
  });
  const [total, setTotal] = useState(0);

  const { ref } = useRef();
  console.log("profile_id", profile_id);

  const initMap = () => {
    console.log("initMap modal map");
    if (show) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        await map.location(
          { lat: position.coords.latitude, lon: position.coords.longitude },
          true
        );

        console.log(
          "place",
          await HelperMap.getPlaceFormLatLong({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          })
        );
        let place = await HelperMap.getPlaceFormLatLong({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
        setCurrentLocation({
          ...place,
          lat: position.coords.latitude,
          long: position.coords.longitude,
          w:
            (place.road ?? "") +
            " " +
            place.subdistrict +
            " " +
            place.district +
            " " +
            place.province +
            " " +
            place.postcode,
          address:
            (place.road ?? "") +
            " " +
            place.subdistrict +
            " " +
            place.district +
            " " +
            place.province +
            " " +
            place.postcode,
        });
        const dot = await new longdo.Marker(
          { lat: position.coords.latitude, lon: position.coords.longitude },
          {
            title: "My Location",
            draggable: false,
            weight: longdo.OverlayWeight.Top,
            icon: {
              html:
                '<div class="d-flex flex-row"><img src="' +
                require("assets/images/work/redDot.gif") +
                '" style="width: 30px;height: 30px"/><span class="font-weight-bold margin-top"></span></div>',
            },
          }
        );
        setCurrentPin(dot);
        await moveMap({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
        await map.Overlays.add(dot);
        markMap();
      });
    }
  };

  useEffect(() => {
    if (show && value && value.lat !== "" && value.long !== "") {
      moveMap({ lat: value.lat, lon: value.long });
    }
  }, [value, currentPin]);
  
  const markMap = async () => {
    if (map) {
      // if (currentPin) {
      //   await map.Overlays.clear();
      // }

      // const dot = await new longdo.Marker(
      //   { lat: value.lat, lon: value.long },
      //   {
      //     title: "My Location",
      //     draggable: true,
      //     clickable: false,
      //     weight: longdo.OverlayWeight.Top,
      //     icon: {
      //       html:
      //         '<div class=""><img src="' +
      //         (value.type === 0
      //           ? require("assets/images/work/pickup_pin.png")
      //           : require("assets/images/work/send_pin.png")) +
      //         '" style="height: 30px"/></div>',
      //     },
      //   }
      // );
      // await moveMap({ lat: value.lat, lon: value.long });
      // await map.Overlays.add(dot);
      // if (currentPin) await map.Overlays.add(currentPin);

      await map.Event.bind("drop", async function () {
        console.log(map.location());
        let overlay = map.location();
        console.log("overlay", overlay.lat, overlay.lon);
        let place = await HelperMap.getPlaceFormLatLong({
          lat: overlay?.lat,
          lon: overlay?.lon,
        });
        if (typeof place === "string" && place?.includes("Too many requests")) {
          Swal.fire({
            html: place,
            type: "info",
          });
        } else if (place?.country !== "ประเทศไทย") {
          Swal.fire({
            html: `ท่านเลือกพิกัดอยู่นอกประเทศไทย`,
            type: "info",
          });
        } else {
          onChange({
            ...value,
            ...place,
            lat: overlay?.lat,
            long: overlay?.lon,
            w:
              (place.road ?? "") +
              " " +
              place.subdistrict +
              " " +
              place.district +
              " " +
              place.province +
              " " +
              place.postcode,
            search:
              (place.road ?? "") +
              " " +
              place.subdistrict +
              " " +
              place.district +
              " " +
              place.province +
              " " +
              place.postcode,
            address:
              (place.road ?? "") +
              " " +
              place.subdistrict +
              " " +
              place.district +
              " " +
              place.province +
              " " +
              place.postcode,
            contact_name: "",
            contact_phone: "",
            place_name: "",
            place_type_id: 0,
            remark: "",
            document: "",
            document_file: [],
          });
          // await moveMap(overlay.location());
        }
      });
    }
  };
  const moveMap = async (location) => {
    map.location(location, true);
  };

  useEffect(() => {
    if (show) {
      // console.log(value);
      initMap();
    } else {
      let ele = document.getElementById("post-work-botbar");
      if (ele) ele.style.height = null;
      setShowLocDetail(false);
    }
  }, [show]);
  return (
    <div>
      <Modal open={show} className="modal-select-map">
        <Box sx={{ ...style }}>
          <div
            style={{
              float: "right",
              textAlign: "end",
            }}
          >
            <IconButton
              onClick={() => {
                close();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <InputLongdoAutoComplete
              value={value}
              label={value?.type === 0 ? "จุดรับสินค้า" : "จุดส่งสินค้า"}
              // error={errors.address}
              onChange={(val) => {
                onChange({
                  ...val,
                  contact_name: "",
                  contact_phone: "",
                  place_name: "",
                  place_type_id: 0,
                  remark: "",
                  document: "",
                  document_file: [],
                });
              }}
              closeFav={closeFav}
            />
          </div>
          <Button
            type="submit"
            fullWidth
            variant="outline"
            size="large"
            style={{ borderRadius: "10px", marginTop: 20 }}
            sx={{
              borderStyle: "solid",
              borderWidth: 1,
              color: "#8ca3ba",
              borderColor: "#8ca3ba",
              height: 50,
            }}
            color="secondary"
            onClick={() => {
              onChange({
                ...value,
                ...currentLocation,
                search: currentLocation?.address,
              });
            }}
          >
            ใช้ตำแหน่งปัจจุบัน
          </Button>
          <div
            style={{
              flex: 1,
              position: "relative",
              marginTop: 20,
              // marginBottom: 20,
            }}
          >
            <div style={{ height: "100%", position: "relative" }}>
              <div>
                <img
                  src={require("assets/images/work/pickup_pin.png")}
                  style={{
                    width: 20,
                    height: 30,
                    position: "absolute",
                    top: `50%`,
                    left: `50%`,
                    transform: "translate(-50%, -50%)", // This centers the image
                    zIndex: 1,
                  }}
                />
              </div>
              {show ? (
                <LongdoMap
                  id={"post-work-modal-map"}
                  ref={ref}
                  style={{ borderRadius: 20 }}
                  map_key={ENV.MAP_KEY[ENV.ENV].KEY_1}
                />
              ) : null}
            </div>

            <div
              style={{
                position: "absolute",
                width: "100%",
                zIndex: 1,
                backgroundColor: "white",
                bottom: -1,
                padding: "10px 10px 10px 10px",
                borderRadius: "20px 20px 0px 0px",
                cursor: "pointer",
                overflow: "scroll",
                justifyContent: "center",
                boxShadow: "rgba(115, 115, 115, 0.1) 0px -1px 10px",
              }}
              id="post-work-botbar"
            >
              <div style={{ display: "flex", flex: 1 }}>
                <div style={{ flex: 0.5 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {
                      close();
                    }}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
                <div style={{ flex: 0.5, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{
                      borderRadius: "10px",
                      marginTop: 20,
                      backgroundColor: "#FC0C0C",
                    }}
                    sx={{ height: 50 }}
                    // color="primary"
                    onClick={() => {
                      confirm();
                    }}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSelectMap;
