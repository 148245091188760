// SwipeComponent.js
import React, { useState } from "react";
import "./swipe.css";

const SwipeUp = ({
  onSwipe = () => {},
  fullSwiped = false,
  minimized40 = false,
  headerContent = null,
  children,
}) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
    onSwipe();
  };

  return (
    <div
      className={`swipe-container ${
        !isMinimized
          ? fullSwiped
            ? "fullSwiped"
            : "swiped"
          : minimized40
          ? "minimized40"
          : "minimized"
      }`}
    >
      <div onClick={handleToggle} style={{width:"100%"}}>
        {/* <div className="headSwipe"></div> */}
        <div>{headerContent}</div>
      </div>
      <div className="childrenSwipe">{children}</div>
    </div>
  );
};

export default SwipeUp;
