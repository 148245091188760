import { Button, Grid, Typography } from "@mui/material";
import "./Backhaul.css";
import FieldSelect from "components/field/FieldSelect";
import { useForm, Controller } from "react-hook-form";
import React, { useState, useEffect } from "react";
import API from "api/emptyTruck/emptyTruck";
import CardBackhaul from "components/backhaul/CardBackhaul";
const api = API.create();
function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);
  return isMobile;
}

const Backhaul = () => {
  const [openCondition, setOpenCondition] = useState(false);

  const [listTruckType, setListTruckType] = useState([]);
  const [listLoadProvince, setListLoadProvince] = useState([]);
  const [listLoadDistrict, setListLoadDistrict] = useState([]);
  const [listUnloadProvince, setListUnloadProvince] = useState([]);
  const [listUnloadDistrict, setListUnloadDistrict] = useState([]);
  const [backhaulData, setBackualData] = useState([]);
  const [totalBackhaul, setTotalBackhaul] = useState(0);
  const [showBackhaul, setShowBackhaul] = useState(false);
  const [submitData, setSubmitData] = useState(null);
  const [page, setPage] = useState(1);
  const dayName = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const today = dayName[new Date().getDay()];
  const [day, setDay] = useState(today);
  const isMobile = useIsMobile();
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      truck_type: "",
      load_province: "",
      load_district: "",
      unload_province: "",
      unload_district: "",
    },
  });

  const listDays = [
    { name: "จ.", value: "monday" },
    { name: "อ.", value: "tuesday" },
    { name: "พ.", value: "wednesday" },
    { name: "พฤ.", value: "thursday" },
    { name: "ศ.", value: "friday" },
    { name: "ส.", value: "saturday" },
    { name: "อา.", value: "sunday" },
  ];

  const watchTruckType = watch("truck_type");
  const watchLoadProvince = watch("load_province");
  const watchLoadDistrict = watch("load_district");
  const watchUnloadProvince = watch("unload_province");
  const watchUnloadDistrict = watch("unload_district");

  useEffect(() => {
    getListEmptyTruckType();
  }, []);

  useEffect(() => {
    if (watchTruckType) {
      setValue("load_province", "");
      setValue("load_district", "");
      setValue("unload_province", "");
      setValue("unload_district", "");
      getList();
    }
  }, [watchTruckType]);

  useEffect(() => {
    if (watchLoadProvince) {
      setValue("load_district", "");
      setValue("unload_province", "");
      setValue("unload_district", "");
      getList();
    }
  }, [watchLoadProvince]);

  useEffect(() => {
    if (watchLoadDistrict) {
      setValue("unload_province", "");
      setValue("unload_district", "");
      getList();
    }
  }, [watchLoadDistrict]);

  useEffect(() => {
    if (watchUnloadProvince) {
      setValue("unload_district", "");
      getList();
    }
  }, [watchUnloadProvince]);

  const getListEmptyTruckType = async () => {
    await api
      .getListEmptyTruck()
      .then((response) => {
        let data = response.data.data;
        setListTruckType(
          ...listTruckType,
          data?.map((res) => ({
            value: res?.truck_type,
            name: res?.truck_type,
          }))
        );
      })
      .catch((e) => console.log(e));
  };
  const getList = async () => {
    const filter = {
      truck_type: watchTruckType ?? "",
      load_province: watchLoadProvince ?? "",
      load_district: watchLoadDistrict ?? "",
      unload_province: watchUnloadProvince ?? "",
      unload_district: watchUnloadDistrict ?? "",
    };
    await api
      .getListLoadUnload(filter)
      .then((response) => {
        let data = response.data.data;
        const sortLoadProv = data?.load_province?.sort((a, b) =>
          a.localeCompare(b, "th")
        );
        const sortLoadDist = data?.load_district?.sort((a, b) =>
          a.localeCompare(b, "th")
        );
        const sortUnloadProv = data?.unload_province?.sort((a, b) =>
          a.localeCompare(b, "th")
        );
        const sortUnloadDist = data?.unload_district?.sort((a, b) =>
          a.localeCompare(b, "th")
        );

        let load_prov = sortLoadProv?.map((res) => ({
          value: res,
          name: res,
        }));
        setListLoadProvince(load_prov);

        let load_dist = sortLoadDist?.map((res) => ({
          value: res,
          name: res,
        }));
        setListLoadDistrict(load_dist);

        let unload_prov = sortUnloadProv?.map((res) => ({
          value: res,
          name: res,
        }));
        setListUnloadProvince(unload_prov);

        let unload_dist = sortUnloadDist?.map((res) => ({
          value: res,
          name: res,
        }));
        setListUnloadDistrict(unload_dist);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (submitData) {
      getBackhaul(submitData, day, page);
    }
  }, [submitData, page]);

  useEffect(() => {
    if (submitData) {
      getBackhaulChangeDay(submitData, day, 1);
    }
  }, [day]);

  const getBackhaul = async (data, _day, _page) => {
    const filter = { ...data, day: _day, page: _page, per_page: 5 };

    await api
      .getEmptyTruck(filter)
      .then((response) => {
        setBackualData((prevItems) => [...prevItems, ...response.data.data]);
        setTotalBackhaul(response.data.total);
        setShowBackhaul(true);
      })
      .catch((e) => console.log(e));
  };
  const getBackhaulChangeDay = async (data, _day, _page) => {
    const filter = { ...data, day: _day, page: _page, per_page: 5 };

    await api
      .getEmptyTruck(filter)
      .then((response) => {
        setBackualData(response.data.data);
        setTotalBackhaul(response.data.total);
      })
      .catch((e) => console.log(e));
  };

  const onSubmit = (data) => {
    setSubmitData(data);
  };
  const renderCondition = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10001,
        }}
      >
        <div
          className="card-condition"
          style={{ zIndex: 10001, width: isMobile ? "85%" : "30%" }}
        >
          <div
            style={{ textAlign: "center", fontWeight: "500", fontSize: "20px" }}
          >
            เงื่อนไขการใช้บริการ
          </div>
          <ul>
            <li style={{ wordBreak: `break-word` }}>
              ขอสงวนสิทธิ์ความคุ้มครองความเสียหายต่อสินค้ากรณีรถเกิดอุบัติเหตุ
              วงเงินคุ้มครองเป็นไปตามเงื่อนไขของบริษัท
              {"  "}
              <a href="https://wemove.co.th/" target="_blank">
                เช็กความคุ้มครอง
              </a>
            </li>
            <li style={{ wordBreak: `break-word` }}>
              ขอสงวนสิทธิ์การชำระเงินค่าขนส่งผ่านทาง บริษัท วีมูฟแพลตฟอร์ม จำกัด
              เท่านั้น เพื่อเป็นการรักษาผลประโยชน์การขนส่ง
            </li>
            <li style={{ wordBreak: `break-word` }}>
              สำหรับลูกค้าองค์กรที่ต้องการเครดิตชำระเงิน
              โปรดติดต่อเจ้าหน้าที่เพื่อลงทะเบียนลูกค้าเครดิต
            </li>
            <li style={{ wordBreak: `break-word` }}>
              ขอสงวนสิทธิ์การให้บริการสำหรับงานที่ต้องการคนงานขึ้นลงสินค้า
            </li>
            <li style={{ wordBreak: `break-word` }}>
              ขอสงวนสิทธิ์ในการนัดช่วงเวลาเข้ารับสินค้าที่แน่นอนภายในช่วงเวลาที่เลือกเท่านั้น
            </li>
            <li style={{ wordBreak: `break-word` }}>
              ขอสงวนสิทธิ์พื้นที่ในการให้บริการเข้ารับสินค้าคลาดเคลื่อนจากอำเภอเมืองได้ไม่เกิน
              20 กม.
            </li>
            <li style={{ wordBreak: `break-word` }}>
              ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขใดใดโดยที่ไม่แจ้งให้ทราบล่วงหน้า
            </li>
          </ul>
          <div>
            <Button
              variant="contained"
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "10px",
                backgroundColor: "#EC1F26",
              }}
              onClick={() => setOpenCondition(false)}
            >
              ปิด
            </Button>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            // top: 0,
            // left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            opacity: 0.7,
            zIndex: 10000,
          }}
        ></div>
      </div>
    );
  };

  return (
    <div className="backhaul-page">
      {openCondition && renderCondition()}
      {isMobile ? (
        !showBackhaul ? (
          <div
            style={{
              backgroundColor: "white",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div className="header-bar-mobile">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={require("assets/images/logo/logo_wemove.jpg")}
                  alt="WM Logo"
                  width={96}
                  height={47}
                />
              </div>
              <div
                style={{
                  fontSize: "24px",
                  padding: "10px",
                  fontWeight: "500",
                }}
              >
                ตารางรถว่างประจำวัน
              </div>
            </div>

            <div style={{ width: "100%", padding: "140px 45px 45px 45px" }}>
              <div style={{ paddingBottom: "20px" }}>
                <img
                  src={require("assets/images/backhaul.png")}
                  alt="backhaul"
                  width={"100%"}
                  height={"auto"}
                />
              </div>

              <div style={{ width: "100%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div style={{ width: "100%" }}>
                    <div className="card-filter">
                      <Controller
                        control={control}
                        name="truck_type"
                        render={({ field: { value, onChange } }) => (
                          <FieldSelect
                            name="ประเภทรถ"
                            options={listTruckType}
                            value={value}
                            onChange={onChange}
                            closePaddingBottom
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="load_province"
                        render={({ field: { value, onChange } }) => (
                          <FieldSelect
                            name="จังหวัดต้นทาง"
                            options={listLoadProvince}
                            value={value}
                            onChange={onChange}
                            closePaddingBottom
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="load_district"
                        render={({ field: { value, onChange } }) => (
                          <FieldSelect
                            name="อำเภอต้นทาง"
                            options={listLoadDistrict}
                            value={value}
                            onChange={onChange}
                            closePaddingBottom
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="unload_province"
                        render={({ field: { value, onChange } }) => (
                          <FieldSelect
                            name="จังหวัดปลายทาง"
                            options={listUnloadProvince}
                            value={value}
                            onChange={onChange}
                            closePaddingBottom
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="unload_district"
                        render={({ field: { value, onChange } }) => (
                          <FieldSelect
                            name="อำเภอปลายทาง"
                            options={listUnloadDistrict}
                            value={value}
                            onChange={onChange}
                            closePaddingBottom
                          />
                        )}
                      />
                    </div>
                  </div>
                </form>
                <div style={{ paddingTop: `20px` }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      backgroundColor: "#EC1F26",
                      "&.Mui-disabled": {
                        backgroundColor: "#9E9E9E",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      watchTruckType === "" ||
                      watchLoadProvince == "" ||
                      watchLoadDistrict == "" ||
                      watchUnloadProvince == "" ||
                      watchUnloadDistrict == ""
                        ? true
                        : false
                    }
                  >
                    ค้นหา
                  </Button>
                </div>
                <div
                  style={{
                    padding: `20px 0`,
                    textAlign: "center",
                    textDecoration: `underline`,
                    color: "#8CA3BA",
                  }}
                  onClick={() => setOpenCondition(true)}
                >
                  อ่านเงื่อนไขในการใช้บริการ
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "white",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div className="header-bar-mobile">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={require("assets/images/logo/logo_wemove.jpg")}
                  alt="WM Logo"
                  width={96}
                  height={47}
                />
              </div>
              <div
                style={{
                  fontSize: "24px",
                  padding: "10px",
                  fontWeight: "500",
                }}
              >
                ตารางรถว่างประจำวัน
              </div>
            </div>

            <div style={{ width: "100%", padding: "45px" }}>
              <div
                style={{
                  display: "flex",
                  justifContent: "start",
                  fontSize: "20px",
                  fontWeight: 500,
                  paddingTop: "80px",
                }}
                onClick={() => window.location.reload()}
              >
                {`< ย้อนกลับ`}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                ประเภทรถ {watchTruckType}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  columnGap: "15px",
                  padding: "20px 0",
                }}
              >
                {listDays?.map((val, i) => (
                  <div
                    className="button-days"
                    style={{
                      backgroundColor: day === val.value ? "#EC1F26" : "white",
                      color: day === val.value ? "white" : "black",
                    }}
                    onClick={() => setDay(val.value)}
                  >
                    {val.name}
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  fontSize: "14px",
                  paddingBottom: "20px",
                }}
              >
                พบรถว่าง {totalBackhaul} รายการ
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "20px",
                }}
              >
                {backhaulData &&
                  backhaulData.map((val) => (
                    <CardBackhaul data={val} day={day} />
                  ))}
              </div>
              {totalBackhaul > 5 && (
                <div style={{ paddingTop: `20px` }}>
                  <Button
                    variant="contained"
                    style={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      border: "2px solid #EC1F26",
                      color: "#EC1F26",
                    }}
                    onClick={() => setPage(page + 1)}
                  >
                    ดูเพิ่มเติม
                  </Button>
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <Grid container>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#B22222",
              height: "100vh",
            }}
          >
            <div className="shadowPic">
              <img
                src={require("assets/images/backhaul-full.png")}
                alt="backhaul full"
                width={"100%"}
                height="auto"
              />
            </div>
          </Grid>

          {!showBackhaul ? (
            <Grid
              item
              xs={6}
              style={{
                padding: `100px`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100vh",
                backgroundColor: "white",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={require("assets/images/logo/logo_wemove.jpg")}
                    alt="WM Logo"
                    width={96}
                    height={47}
                  />
                </div>
                <div
                  style={{
                    fontSize: "24px",
                    padding: "10px",
                    fontWeight: "500",
                  }}
                >
                  ตารางรถว่างประจำวัน
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ width: "100%" }}>
                  <div className="card-filter-desktop">
                    <Controller
                      control={control}
                      name="truck_type"
                      render={({ field: { value, onChange } }) => (
                        <FieldSelect
                          name="ประเภทรถ"
                          options={listTruckType}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="load_province"
                      render={({ field: { value, onChange } }) => (
                        <FieldSelect
                          name="จังหวัดต้นทาง"
                          options={listLoadProvince}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="load_district"
                      render={({ field: { value, onChange } }) => (
                        <FieldSelect
                          name="อำเภอต้นทาง"
                          options={listLoadDistrict}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="unload_province"
                      render={({ field: { value, onChange } }) => (
                        <FieldSelect
                          name="จังหวัดปลายทาง"
                          options={listUnloadProvince}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="unload_district"
                      render={({ field: { value, onChange } }) => (
                        <FieldSelect
                          name="อำเภอปลายทาง"
                          options={listUnloadDistrict}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
              <div>
                <div style={{ paddingTop: "20px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      backgroundColor: "#EC1F26",
                      "&.Mui-disabled": {
                        backgroundColor: "#9E9E9E",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      watchTruckType === "" ||
                      watchLoadProvince == "" ||
                      watchLoadDistrict == "" ||
                      watchUnloadProvince == "" ||
                      watchUnloadDistrict == ""
                        ? true
                        : false
                    }
                  >
                    ค้นหา
                  </Button>
                </div>
                <div
                  style={{
                    paddingTop: `30px`,
                    textAlign: "center",
                    textDecoration: `underline`,
                    color: "#8CA3BA",
                  }}
                  onClick={() => setOpenCondition(true)}
                >
                  อ่านเงื่อนไขในการใช้บริการ
                </div>
              </div>
            </Grid>
          ) : (
            <Grid
              item
              xs={6}
              style={{
                padding: `100px`,
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                height: "100vh",
                backgroundColor: "white",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={require("assets/images/logo/logo_wemove.jpg")}
                    alt="WM Logo"
                    width={96}
                    height={47}
                  />
                </div>
                <div
                  style={{
                    fontSize: "24px",
                    padding: "10px",
                    fontWeight: "500",
                  }}
                >
                  ตารางรถว่างประจำวัน
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
                onClick={() => window.location.reload()}
              >
                {`< ย้อนกลับ`}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                ประเภทรถ {watchTruckType}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  columnGap: "15px",
                  padding: "20px 0",
                }}
              >
                {listDays.map((val, i) => (
                  <div
                    className="button-days"
                    style={{
                      backgroundColor: day === val.value ? "#EC1F26" : "white",
                      color: day === val.value ? "white" : "black",
                    }}
                    onClick={() => setDay(val.value)}
                  >
                    {val.name}
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  fontSize: "14px",
                  paddingBottom: "20px",
                }}
              >
                พบรถว่าง {totalBackhaul} รายการ
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "20px",
                }}
              >
                {backhaulData &&
                  backhaulData.map((val) => (
                    <CardBackhaul data={val} day={day} />
                  ))}
              </div>
              {totalBackhaul > 5 && (
                <div style={{ paddingTop: `20px` }}>
                  <Button
                    variant="contained"
                    style={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      border: "2px solid #EC1F26",
                      color: "#EC1F26",
                    }}
                    onClick={() => setPage(page + 1)}
                  >
                    ดูเพิ่มเติม
                  </Button>
                </div>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Backhaul;
