// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-in-card-backhaul {
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/backhaul/CardBackhaul.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iDAAiD;EACjD,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,WAAW;EACX,YAAY;AACd","sourcesContent":[".box-in-card-backhaul {\n  border-radius: 10px;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n  background-color: white;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
