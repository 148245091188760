import helper from "utils/helper";

const create = () => {
  const getListLoadUnload = async (data) => {
    let api = await helper.setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("/api/empty_truck/get_list_load_and_unload" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };

  const getListEmptyTruck = async () => {
    let api = await helper.setAPI();
    return api.get("/api/empty_truck/truck_type");
  };

  const getEmptyTruck = async (data) => {
    let api = await helper.setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("/api/empty_truck" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
  return {
    getListLoadUnload,
    getListEmptyTruck,
    getEmptyTruck
  };
};

export default { create };
