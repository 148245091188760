import Backhaul from "pages/Backhaul";
import CheckWorkPrice from "pages/CheckPrice/CheckWorkPrice";
import Home from "pages/Home/Home";
import OpenApp from "pages/OpenApp/OpenApp";
import WorkPublicDetail from "pages/WorkPublic/WorkPublicDetail";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const routes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/check-price" element={<CheckWorkPrice />} />
        {/* <Route path='/open-app/:wid' element={<OpenApp />} /> */}
        <Route path="/backhaul" element={<Backhaul />} />
        <Route path="/public/tracking/:id_secret" element={<WorkPublicDetail />} />
      </Routes>
    </Router>
  );
};

export default routes;
