import React, { useState, useEffect } from "react";

function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);
  return isMobile;
}

const ModalCustom = ({
  content = () => {},
  open = false,
  onClose = () => {},
}) => {
  const isMobile = useIsMobile();
  return open? (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10001,
      }}
    >
      <div
        style={{
          zIndex: 10001,
          width: isMobile ? "85%" : "30%",
          backgroundColor: `white`,
          boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
          borderRadius: `10px`,
          padding: `30px`,
        }}
      >
        {content()}
      </div>
      <div
        style={{
          position: "absolute",
          // top: 0,
          // left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          opacity: 0.7,
          zIndex: 10000,
        }}
        onClick={onClose}
      ></div>
    </div>
  ) : null;
};

export default ModalCustom;
