import axios from "axios";
import moment from "moment";

const monthsFull = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];
const monthsShort = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

export default {
  async setAPI() {
    return axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      timeout: 100000,
    });
  },

  addhtpps(url) {
    if (url.includes("https://") || url.includes("http://")) {
      return url;
    } else {
      return process.env.REACT_APP_ENDPOINT === "SIT"
        ? "http://" + url
        : "https://" + url;
    }
  },
  pathImage() {
    return `${process.env.REACT_APP_MINIO_ENDPOINT}/${process.env.REACT_APP_MINIO_BUCKET}/`;
  },
  workDetailProduct(data) {
    data = data.sort((a, b) => a.id - b.id);

    let product = data.map((e) => {
      let prod = e.workLocationProducts.map((p) => {
        let prod_img = p.work_location_product_image.map((i) => {
          return { src: this.addhtpps(i.image) };
        });
        return {
          name: p.product.name,
          product_id: p.product.id,
          image: this.pathImage() + p.product.image,
          example: p.product.example,
          product_name: p.product_name,
          product_size: p.stl_standard_size
            ? p.stl_standard_size.name.split(" ")[1]
            : "",
          product_width: p.width,
          product_length: p.length,
          product_height: p.height,
          product_volume: p.volume,
          product_weight: p.weight,
          product_count_type: p.count_type,
          product_amount: p.amount,
          product_image: prod_img,
          product_image_per_route: [],
        };
      });
      return {
        type: e.location_type,
        name: e.location_name,
        lat: e.lat,
        long: e.long,
        address: e.address,
        district: e.district,
        subdistrict: e.subdistrict,
        province: e.province,
        postal_code: e.postal_code,
        contact_name: e.contact_name,
        contact_phone: e.contact_phone,
        place_name: e.address,
        place_type_id: e.place_type,
        remark: e.remark,
        document: e.document,
        document_file: [],
        product: prod,
      };
    });
    return product;
  },
  getCookie(params) {
    let name = params + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  secToText(allInterval) {
    let order_interval = "";
    if (allInterval.toFixed(2) > 60) {
      // จากนาทีเป็น ชม
      let interval = parseFloat(allInterval / 60);
      // job.orderDetail.order_interval =
      //     interval.toFixed(2) + " ชม.";
      let string = interval.toFixed(2) + "";
      let time = string.split(".");
      if (time.length === 2) {
        if (time[1] > 60) {
          let int = parseInt(time[1]);
          let min = parseFloat(int / 60);
          let intH = parseInt(time[0]);
          let hours = parseFloat(intH + min);
          let hToS = hours.toFixed(2).toString();
          let showTime = hToS.split(".");
          if (showTime[1] === 60) {
            order_interval = parseInt(showTime[0]) + 1 + " ชม. ";
          } else {
            order_interval =
              showTime[0] +
              " ชม. " +
              ((showTime[1] / 100) * 60).toFixed(0) +
              " นาที";
          }
        } else {
          // console.log(
          //     "testt",
          //     time[0] + " ชม. " + time[1] + " นาที"
          // );
          if (time[1] === 60) {
            order_interval = parseInt(time[0]) + 1 + " ชม. ";
          } else {
            order_interval =
              time[0] + " ชม. " + ((time[1] / 100) * 60).toFixed(0) + " นาที";
          }
        }
      }
      // else {
      //     console.log("test", time);
      //     job.orderDetail.order_interval =
      //         time[0] + " ชม. " + time[1] + " นาที";
      // }
    } else {
      if (allInterval % 1 !== 0) {
        order_interval =
          Math.floor(allInterval) +
          " นาที " +
          60 * (allInterval % 1) +
          " วินาที";
      } else {
        order_interval = allInterval.toFixed(0) + " นาที";
      }
    }

    return order_interval;
  },
  momentDateTime(datetime, type = "short", utc = false) {
    // console.log(datetime);
    // datetime += '+7000';
    let year = parseInt(moment(datetime).format("YYYY"));
    let dd = moment(datetime).get("date");
    // console.log(dd);
    let month = moment(datetime).get("month");
    let h = moment(datetime).format("HH");
    let m = moment(datetime).format("mm");
    let date;
    if (utc) {
      date = new Date(Date.UTC(year, month, dd, h, m));
    } else {
      date = new Date(year, month, dd, h, m);
    }

    if (date) {
      let year = parseInt(moment(date).format("YYYY")) + 543;
      let dd = moment(date).get("date");
      let month;
      if (type === "short") {
        month = monthsShort[moment(date).get("month")];
      } else {
        month = monthsFull[moment(date).get("month")];
      }
      let time = moment(date).format("HH:mm");
      return dd + " " + month + " " + year + " " + time + " น.";
    } else {
      return "-";
    }
  },
  setDateLocale(datetime) {
    if (datetime.toString().includes(`GMT+0700`)) {
      return new Date(datetime);
    } else {
      let year = parseInt(moment(datetime).format("YYYY"));
      let dd = moment(datetime).get("date");
      let month = moment(datetime).get("month");
      let h = moment(datetime).format("HH");
      let m = moment(datetime).format("mm");
      let date = new Date(Date.UTC(year, month, dd, h, m));
      return date;
    }
  },
  momentTwoDate(startDate, endDate, type = "short", utc = false) {
    let start = this.setDateLocale(startDate);
    let end = this.setDateLocale(endDate);
    let year = parseInt(moment(start).format("YYYY")) + (utc ? 543 : 0);
    let dd = moment(start).get("date");
    let month;
    if (type === "short") {
      month = monthsShort[moment(start).get("month")];
    } else {
      month = monthsFull[moment(start).get("month")];
    }

    let yearEnd = parseInt(moment(end).format("YYYY")) + (utc ? 543 : 0);
    let ddEnd = moment(end).get("date");
    // let monthEnd = monthsShort[moment(end).get('month')];
    let monthEnd;
    if (type === "short") {
      monthEnd = monthsShort[moment(end).get("month")];
    } else {
      monthEnd = monthsFull[moment(end).get("month")];
    }
    if (monthEnd === month) {
      if (dd === ddEnd) {
        return dd + " " + month + " " + year;
      } else {
        return dd + " - " + ddEnd + " " + month + " " + year;
      }
    } else {
      if (year === yearEnd) {
        return dd + " " + month + " - " + ddEnd + " " + monthEnd + " " + year;
      } else {
        return (
          dd +
          " " +
          month +
          " " +
          year +
          " - " +
          ddEnd +
          " " +
          monthEnd +
          " " +
          year
        );
      }
    }
  },
  addComma(number) {
    if (number) {
      number = parseFloat(number);
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "-";
    }
  },
  
  addCommaNoDigit(number) {
    if (number) {
      number = parseFloat(number);
      return number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "-";
    }
  },
  getParam(body) {
    let param = "";
    let key = Object.keys(body);
    key.forEach((e, i) => {
      if (typeof body[e] === "object") {
        body[e].forEach((ob, obi) => {
          if (obi !== body[e].length - 1 || i !== key.length - 1) {
            param += e + "=" + ob + "&";
          } else {
            param += e + "=" + ob;
          }
        });
      } else {
        if (i !== key.length - 1) {
          param += e + "=" + body[e] + "&";
        } else {
          param += e + "=" + body[e];
        }
      }
    });
    return param;
  },
};
